import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Section from "@components/section";
import {
  BlockDescription,
  BlockHeader,
  Wrapper,
} from "@components/shared.styled";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Section justify="center">
      <Wrapper>
        <BlockHeader>404: Not Found</BlockHeader>
        <BlockDescription>
          <center>
            You just hit a route that doesn&#39;t exist... the sadness.
          </center>
        </BlockDescription>
      </Wrapper>
    </Section>
  </Layout>
);

export default NotFoundPage;
